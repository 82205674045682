import React, { useState } from "react";

const Header = ({ icons, links, sports }) => {
  const { arrow, logo, close, menu } = icons;
  const { home, navButtons, gradaInfo } = links;
  const [overlay, setOverlay] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [showDrop, setShowDrop] = useState(false);

  return (
    <>
      <div className="navbar-mobile">
        <a href={home} className="navbar__logo">
          <span dangerouslySetInnerHTML={{ __html: logo }} />
        </a>
        <button
          className="navbar__dropdown"
          onClick={() => setOverlay(!overlay)}
          dangerouslySetInnerHTML={{ __html: overlay ? close : menu }}
        />
      </div>

      {overlay && (
        <div className="overlay">
          <div className="sports">
            {sports.map(({ url, name, icon }, index) => {
              if (index < 3 || showAll) {
                return (
                  <a className="sports__item" href={url} key={name}>
                    <img className="sports__item__icon" src={icon} />
                    <span>{name}</span>
                  </a>
                );
              }
            })}
            <button className="sports__button" onClick={() => setShowAll(!showAll)}>
              <span
                className={`${showAll ? "--invert" : ""} sports__button__icon`}
                dangerouslySetInnerHTML={{ __html: arrow }}
              />
              <span>{showAll ? "Menos" : "Más"}</span>
            </button>
          </div>
          <div className="grada-navigation">
            <a className="grada-navigation__item" href={home}>
              Inicio
            </a>
            {navButtons.map((btn) => (
              <a
                className="grada-navigation__item"
                href={btn["web_page"]}
                key={btn["name"]}
              >
                {btn["name"]}
              </a>
            ))}
            <button
              className="grada-navigation__item-list"
              onClick={() => setShowDrop(!showDrop)}
            >
              <span className="grada-navigation__item-list__name">Más</span>
              <span
                className={`${showDrop ? "--invert" : ""}`}
                dangerouslySetInnerHTML={{ __html: arrow }}
              />
            </button>
            {showDrop && (
              <div className="grada-navigation__item-list__content">
                {gradaInfo.map(({ name, url }) => (
                  <a
                    href={url}
                    key={name}
                    className="grada-navigation__item-list__content__item"
                  >
                    {name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
