import React from 'react'
import Player from './ott/streaming/VideoPlayer';

const streamBaseUrl = 'https://d318ayjbtf00wh.cloudfront.net/stream_';

const VideoPlayer = ({ url, stream, videoProps={} }) => (
  <div className='video-player'>
    {!stream && (
      <Player
        sources={{ src: url }}
        controls={ true }
        {...videoProps}
      />
    )}
    {stream && (
      <Player
        sources={[
          {src: streamBaseUrl + '1080-' + stream, label: '1080p', res: '1080'},
          {src: streamBaseUrl + '720-' + stream, label: '720p', res: '720'},
          {src: streamBaseUrl + '480-' + stream, label: '480p', res: '480'},
          {src: streamBaseUrl + '240-' + stream, label: '240p', res: '240'}
        ]}
        controls={ true }
        qualitySelector={true}
        {...videoProps}
      />
    )}
  </div>
);

export default VideoPlayer