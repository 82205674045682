import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
require("@silvermine/videojs-quality-selector")(videojs);

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props);
    this.player.controlBar.addChild("QualitySelector");

    if (this.props.startAt) {
      this.player.currentTime(this.props.startAt);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <>
        <div data-vjs-player style={{ width: "100%", height: "100%" }}>
          <video
            ref={(node) => {
              this.videoNode = node;
            }}
            className="video-js"
          />
        </div>
      </>
    );
  }
}
