import React from "react";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";

const VideoHeader = ({ videos }) => (
  <>
    <Swiper
      pagination={true}
      navigation={true}
      modules={[Navigation, Pagination]}
      className="video-swiper"
    >
      {videos.map(({ title, subtitle, description, cta, background }) => (
        <SwiperSlide key={title}>
          <div className="card">
            <div className="card__image">
              <img src={background} alt={title} />
            </div>
            <div className="card__content">
              <h3 className="card__content__title">{title}</h3>
              <span className="card__content__subtitle">{subtitle}</span>
              <p className="card__content__description">{description}</p>
              <a href={cta} className="card__content__cta">
                VER
              </a>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </>
);

VideoHeader.propTypes = {};

export default VideoHeader;
