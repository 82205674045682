import React, { useEffect, useState } from "react";

const JsonRenderer = ({ url }) => {
  const [json, setJson] = useState("");

  useEffect(() => {
    const getJson = async () => {
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      setJson(data);
    };

    getJson();
  }, []);

  return <script type="application/ld+json">{JSON.stringify(json)}</script>;
};

export default JsonRenderer;
